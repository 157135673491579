import { useInfiniteQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useMemo } from "react";
import { useConvoContext } from "../../../../contexts/ConvoContext";

export const useQConvo_Comments = () => {
   const { sosAxios } = useSosAxios();
   const filters = { limit: 5000 };

   const { convoState } = useConvoContext();

   const { tid } = convoState;

   const query = useInfiniteQuery({
      enabled: !!tid,
      queryKey: [`useQCon_getCommentsInThead`, tid],
      staleTime: 500,
      queryFn: ({ pageParam: offset }) =>
         sosAxios
            .get(`/conversations/threads/${tid}/comments`, { params: { ...filters, offset } })
            .then<TUseQPVi_Threads_Data>(({ data }) => {
               return data;
            }),
      initialPageParam: 0,
      getNextPageParam: ({ metadata: { nextOffset } }) => {
         return nextOffset > 0 && nextOffset >= +filters.limit ? nextOffset : undefined;
      },
   });
   const comments = useMemo(
      () => query.data?.pages.flatMap((page) => page.payload.comments) || [],
      [query.data?.pages]
   );

   return { ...query, comments };
};
export type TUseQCon_getCommentsInThead = {
   tid: number;
};

type UseQCon_getCommentsInThead_User = {
   uid: string;
   name: string;
   picture: string;
   roles: string[];
};

export type TUseQCon_getCommentsInThead_Comment = {
   cid: number;
   content: string;
   created_at: string;
   commentor: UseQCon_getCommentsInThead_User;
};

export type UseQCon_getCommentsInThead_Response = {
   comments: TUseQCon_getCommentsInThead_Comment[];
};

type TUseQPVi_Threads_Data = {
   payload: UseQCon_getCommentsInThead_Response;
   metadata: { totalRows: number; nextOffset: number };
};
