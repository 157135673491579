import classNames from "classnames/bind";
import styles from "./TransactionListView.module.scss";
import DataTable from "react-data-table-component";
import { allTransactionColumn, customStyles } from "./data-table-column";
import { TransactionActiveContractModal } from "../TransactionActiveContractModal/TransactionActiveContractModal";
import { SHrPopup } from "@simplyhomes/react";
import {
   Transactions_GetTransactions_Transaction_Inspection,
   useQTrans_Transactions,
} from "../../../hooks/transactions/queries/useQTrans_Transactions";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useOffersContext } from "../../../contexts/OffersContext";
import {
   useSoSOptions_Transaction_ClosingStatus,
   useSoSOptions_Transaction_EMDStatus,
   useSoSOptions_Transaction_FinancingTeam,
   // useSoSOptions_Transaction_Inspection,
   useSoSOptions_Transaction_TitleCompany,
} from "../../../hooks/queries/options/useQSoS_Options";
const cx = classNames.bind(styles);

export const TransactionListView = () => {
   const { offersOverwrite } = useOffersContext();
   const { transactionsState, transactionsOverwrite } = useTransactionsContext();

   const { showDetailsModal } = transactionsState;
   const a = useQTrans_Transactions().data;

   const handleSelectedTransaction = (oid: number, lid: number) => {
      transactionsOverwrite({ oid, showDetailsModal: true });
      offersOverwrite({ lid });
   };

   return (
      <div className={cx("container")}>
         <DataTable
            columns={allTransactionColumn}
            data={a?.pages.flatMap((i) => i.payload.transactions) || []}
            customStyles={customStyles}
            onRowClicked={({ oid, lid }) => handleSelectedTransaction(oid, lid)}
         />
         <SHrPopup show={showDetailsModal}>
            <TransactionActiveContractModal />
         </SHrPopup>
      </div>
   );
};

export const TransactionStatus = ({
   EMDStatus,
   inspectionStatus,
   closingStatus,
   titleStatus,
   financingStatus,
}: TTransactionStatusProps) => {
   // const qInspection = useSoSOptions_Transaction_Inspection().data?.options || [];
   // const inspectionColor = inspectionStatus?.map((i) => qInspection.find((o) => o.label === i.status)?.color);

   const qEMDStatus = useSoSOptions_Transaction_EMDStatus().data?.options || [];
   const EMDStatusColor = qEMDStatus.find((o) => o.label === EMDStatus)?.color;

   const qTitleCompany = useSoSOptions_Transaction_TitleCompany().data?.options || [];
   const TitleCompanyColor = qTitleCompany.find((o) => o.label === titleStatus)?.color;

   const qFinancingTeam = useSoSOptions_Transaction_FinancingTeam().data?.options || [];
   const FinancingStatusColor = qFinancingTeam.find((o) => o.label === financingStatus)?.color;

   const qClosingStatus = useSoSOptions_Transaction_ClosingStatus().data?.options || [];
   const ClosingStatusColor = qClosingStatus.find((o) => o.label === titleStatus)?.color;

   const color = EMDStatusColor || TitleCompanyColor || FinancingStatusColor || ClosingStatusColor;

   return (
      <div>
         <div className={cx("status")} style={{ backgroundColor: color }}>
            {EMDStatus}
            {inspectionStatus?.map((i) => i.status)}
            {closingStatus}
            {titleStatus}
         </div>
      </div>
   );
};

type TTransactionStatusProps = {
   EMDStatus?: string;
   inspectionStatus?: Transactions_GetTransactions_Transaction_Inspection[];
   closingStatus?: string;
   titleStatus?: string;
   financingStatus?: string;
};
