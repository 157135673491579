import {
   useSoSOptions_Transaction_ClosingStatus,
   useSoSOptions_Transaction_EMDStatus,
   useSoSOptions_Transaction_FinancingTeam,
   useSoSOptions_Transaction_Inspection,
   useSoSOptions_Transaction_TitleCompany,
} from "../../queries/options/useQSoS_Options";

export const useTransactionOptions = () => {
   const qEMDStatusOptions = useSoSOptions_Transaction_EMDStatus().data?.options;
   const qInspectionStatusOptions = useSoSOptions_Transaction_Inspection().data?.options || [];
   const qTitleCompanyStatusOptions = useSoSOptions_Transaction_TitleCompany().data?.options || [];
   const qFinancingStatusOptions = useSoSOptions_Transaction_FinancingTeam().data?.options || [];
   const qClosingStatusOptions = useSoSOptions_Transaction_ClosingStatus().data?.options || [];

   return {
      qEMDStatusOptions,
      qInspectionStatusOptions,
      qTitleCompanyStatusOptions,
      qFinancingStatusOptions,
      qClosingStatusOptions,
   };
};
