import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../contexts/ToastContext";
import { useSosAxios } from "../../axios/useSosAxios";

export const useM_Options_CreateOption = () => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Schedule Inspection is successful!",
            },
         });
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Schedule Inspection is failed!",
            },
         });
      },

      mutationFn: (createBody: SoS_Options_CreateOption_Body_Dto) =>
         sosAxios.post(`/options`, { ...createBody }).then<SoS_Options_CreateOption_Response>(({ data }) => data),
   });
};

export class SoS_Options_CreateOption_Response {
   message!: string;
}

export class SoS_Options_CreateOption_Body_Dto {
   value!: string;
   label!: string;
   moid!: string;
   color?: string;
   priority?: number;
}
