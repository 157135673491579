import classNames from "classnames/bind";
import { useEffect, useMemo, useState } from "react";

import styles from "./OffersCreateAndUpdateOfferModal.module.scss";

import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { useToastContext } from "../../../contexts/ToastContext";
import { useQOffers_Lead } from "../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { useOffersContext } from "../../../contexts/OffersContext";
import { SoS_TermTemplate } from "../../../hooks/offers/queries/termTemplate/useQOffers_GetTermTemplateById";
import { useMOffers_UpdateOffer } from "../../../hooks/offers/mutates/offer/useMOffers_UpdateOffer";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { OffersPropertiesSelection } from "../OffersPropertiesSelection/OffersPropertiesSelection";
import { OffersAddPropertiesToCreateOfferModal } from "../OffersAddPropertiesToCreateOfferModal/OffersAddPropertiesToCreateOfferModal";

import {
   SoS_GetOptions_Option,
   useSoSOptions_Offers_OfferTypes,
   useSoSOptions_Offers_Simply_Terms,
} from "../../../hooks/queries/options/useQSoS_Options";
import { useMOffers_CreateOffer } from "../../../hooks/offers/mutates/offer/useMOffers_CreateOffer";
import { useQOffers_GetLeadSourceProperties } from "../../../hooks/offers/queries/leads/useQOffers_GetLeadSourceProperties";
import { useQOffers_GetLeads } from "../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { OffersEditTermTemplateCreateOfferModal } from "../OffersEditTermTemplateModal/OffersEditTermTemplateModal";
import { formatDate } from "../OffersLeadCard/OffersLeadCard";

const cx = classNames.bind(styles);

export type OffersCreateAndUpdateOfferTermTemplate = {
   termTemplateData: SoS_TermTemplate;
};

const convertTimestampToDate = (timestamp: number): string => {
   const date = new Date(timestamp);
   const year = date.getFullYear();
   const month = String(date.getMonth() + 1).padStart(2, "0");
   const day = String(date.getDate()).padStart(2, "0");
   return `${year}-${month}-${day}`;
};

export const OffersCreateAndUpdateOfferModal = () => {
   const { toastDispatch } = useToastContext();

   const { offersState, offersDispatch } = useOffersContext();

   const { oid } = offersState;

   const qOffersGetLead = useQOffers_Lead();

   const qOffersGetLeads = useQOffers_GetLeads();

   const { data } = qOffersGetLead;

   const sid = data?.lead.sid!;

   const offer = data?.lead?.offersMap[oid];

   const offerTypesData = useSoSOptions_Offers_OfferTypes();

   const offerTypes = useMemo(() => offerTypesData.data?.options || [], [offerTypesData]);

   const qOffersGetLeadSourceProperties = useQOffers_GetLeadSourceProperties({ sid });

   const qOffersSimplyTermOptions = useSoSOptions_Offers_Simply_Terms().data?.options || [];

   const [expireDate, setExpireDate] = useState(
      offer?.expire_date?.split("T")[0] || convertTimestampToDate(new Date().setDate(new Date().getDate() + 30))
   );

   const { mutate, isPending } = useMOffers_CreateOffer({
      onSuccess: () => {
         qOffersGetLead.refetch();
         qOffersGetLeads.refetch();
         offersDispatch({ overwrite: { showCreateAndUpdateOfferModal: false } });
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create new offer is successful!",
            },
         });
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create new offer is failed!",
            },
         }),
   });

   const { mutate: updateOfferMutate, isPending: updateOfferIsPending } = useMOffers_UpdateOffer();

   const getleadSourceProperties = useMemo(
      () => offer?.properties || qOffersGetLeadSourceProperties.data?.properties || [],
      [offer?.properties, qOffersGetLeadSourceProperties.data?.properties]
   );

   const [isShowTermTemplateModal, setIsShowTermTemplateModal] = useState(false);
   const [isShowEditPropertiesModal, setIsShowEditPropertiesModal] = useState(false);

   const [offerTypeSelected, setOffertypeSelected] = useState<SoS_GetOptions_Option>(
      offerTypes.find((ot) => ot.value === offer?.type) || { label: "", value: "" }
   );
   const [termTemplate, setTermTemplate] = useState<OffersCreateAndUpdateOfferTermTemplate>({
      termTemplateData: {
         simply: [],
         additional: [],
      },
   });
   const [pids, setPids] = useState<string[]>([]);

   const isUpdateMode = !!oid;

   useEffect(() => {
      setPids(getleadSourceProperties.map(({ pid }) => pid));
   }, [getleadSourceProperties]);

   useEffect(() => {
      if (!qOffersGetLead.data) return;

      const terms = offer ? offer.terms : qOffersGetLead.data.lead.terms;

      setTermTemplate({
         termTemplateData: terms,
      });

      if (offerTypes && offerTypes.length)
         setOffertypeSelected(offerTypes.find((ot) => ot.value === offer?.type) || { label: "", value: "" });
   }, [offer, offerTypes, qOffersGetLead.data]);

   const handleAddNewSimplyTermsItem = () =>
      setTermTemplate((prev) => ({
         ...prev,
         termTemplateData: {
            ...prev.termTemplateData,
            simply: [...prev.termTemplateData.simply, { title: "", default: "" }],
         },
      }));

   const handleUpdateSimplyTermsItem = (index: number, title: string, defaultValue: string) =>
      setTermTemplate((prev) => ({
         ...prev,
         termTemplateData: {
            ...prev.termTemplateData,
            simply: prev.termTemplateData.simply.map((term, i) =>
               i === index ? { title, default: defaultValue } : term
            ),
         },
      }));

   const handleRemoveSimplyTermsItem = (index: number) =>
      setTermTemplate((prev) => ({
         ...prev,
         termTemplateData: {
            ...prev.termTemplateData,
            simply: prev.termTemplateData.simply.filter((_, i) => i !== index),
         },
      }));

   const handleAddNewAdditionalTermsItem = () =>
      setTermTemplate((prev) => ({
         ...prev,
         termTemplateData: {
            ...prev.termTemplateData,
            additional: [...prev.termTemplateData.additional, ""],
         },
      }));

   const handleUpdateAdditionalTermsItem = (index: number, value: string) =>
      setTermTemplate((prev) => ({
         ...prev,
         termTemplateData: {
            ...prev.termTemplateData,
            additional: prev.termTemplateData.additional.map((term, i) => (i === index ? value : term)),
         },
      }));

   const handleRemoveAdditionalTermsItem = (index: number) =>
      setTermTemplate((prev) => ({
         ...prev,
         termTemplateData: {
            ...prev.termTemplateData,
            additional: prev.termTemplateData.additional.filter((_, i) => i !== index),
         },
      }));

   const handleCreateNewOffer = () => {
      mutate({
         expire_date: expireDate,
         type: offerTypeSelected.value || "",
         terms: termTemplate.termTemplateData,
         pids,
      });
   };

   const handleUpdateOffer = () => {
      if (!oid) return;
      updateOfferMutate({
         oid,
         type: offerTypeSelected.value || "",
         terms: termTemplate.termTemplateData,
         pids,
      });
   };

   const handleApplyProperties = (pidsChanged: string[]) => {
      setPids(pidsChanged);
      setIsShowEditPropertiesModal(false);
      toastDispatch({
         type: "ADD_TOAST",
         payload: {
            type: "success",
            message: "Add properties to create offer is successful!",
         },
      });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/edit_note_black.svg")} alt="" />
            </div>
            <span>{isUpdateMode ? "Edit Offer" : "Create an Offer"} </span>
            <div className={cx("closeBtn")}>
               <button
                  type="button"
                  title="close"
                  onClick={() =>
                     offersDispatch({ overwrite: { showCreateAndUpdateOfferModal: false, ...(oid > 0 && { oid: 0 }) } })
                  }
               >
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         <div className={cx("contentC")}>
            <div className={cx("picksC")}>
               <div className={cx("pickC")}>
                  <div className={cx("expireDate")}>
                     <SoSInputTitle
                        title="Expire Date"
                        type="date"
                        inputProps={{ min: new Date().toISOString().split("T")[0] }}
                        value={expireDate}
                        handleValue={(e) => {
                           setExpireDate(convertTimestampToDate(new Date(e).setDate(new Date(e).getDate())));
                        }}
                     />
                  </div>
               </div>
               <div className={cx("pickC")}>
                  <span>
                     <b>Offer Type</b>
                  </span>
                  <SoSInputSelect
                     isMulti={false}
                     value={offerTypeSelected}
                     onChange={(o) => setOffertypeSelected(o)}
                     options={offerTypes}
                  />
               </div>
            </div>
            <div className={cx("titleC")}>
               <span>Choose a Term Template</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setIsShowTermTemplateModal(true),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/offer/edit_black.svg")} alt="" />
                  </div>
                  Term Setting
               </SHrSosButton>
            </div>
            <div className={cx("termsC")}>
               <div className={cx("simplyTermC")}>
                  <span>
                     <b>Simply Terms</b>
                  </span>
                  {termTemplate.termTemplateData.simply.map((term, i) => (
                     <SoSInputTitle
                        key={i}
                        title={qOffersSimplyTermOptions.find((o) => o.value === term.title)?.label}
                        type="text"
                        value={term.default.toString()}
                        isNotChanged
                     />
                  ))}
               </div>

               <div className={cx("additionalTermsC")}>
                  <div className={cx("titleAdditionalC")}>
                     <span>
                        <b>Additional Terms</b>
                     </span>
                  </div>
                  {termTemplate.termTemplateData.additional.map((term, i) => (
                     <div className={cx("additionalTermC")} key={i}>
                        <span>{term}</span>
                     </div>
                  ))}
               </div>
            </div>

            <div className={cx("souceWrapper")}>
               <span>Souce</span>
               <div className={cx("sourceInfoC")}>
                  <div className={cx("sourceTypeC")}>
                     <span>
                        Source type: <b>{qOffersGetLead.data?.lead.source.source || "N/A"}</b>
                     </span>
                     <span>
                        Date submitted: <b>{formatDate(qOffersGetLead.data?.lead.source.created_at || "") || "N/A"}</b>
                     </span>
                  </div>

                  {qOffersGetLead.data?.lead.source.contacts.map((contact, i) => (
                     <div className={cx("contactC")} key={i}>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/person_black.svg")} alt="" />
                           </div>
                           <span>{contact.name}</span>
                        </div>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/email_black.svg")} alt="" />
                           </div>
                           <span>{contact.email}</span>
                        </div>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/phone_black.svg")} alt="" />
                           </div>
                           <span>{contact.phone_primary}</span>
                        </div>
                     </div>
                  ))}
                  {/* )} */}
               </div>
            </div>

            <div className={cx("propertiesAndDocumentC")}>
               <span>Properties & Documents</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setIsShowEditPropertiesModal(true),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                  </div>
                  Edit property
               </SHrSosButton>
            </div>
            <div className={cx("propertiesC")}>
               {qOffersGetLeadSourceProperties.isLoading ? (
                  <div className={cx("loading")}>
                     <SHrSpinner /> <span>Loading Properties...</span>
                  </div>
               ) : (
                  pids.map((pid, i) => (
                     <OffersPropertiesSelection
                        key={i}
                        property={
                           getleadSourceProperties.find((p) => p.pid === pid)! ||
                           qOffersGetLeadSourceProperties.data?.properties.find((p) => p.pid === pid)!
                        }
                     />
                  ))
               )}
            </div>
         </div>

         <SoSConfirmWrapper
            title={
               isUpdateMode
                  ? "Are you sure you want to update this offer?"
                  : "Are you sure you want to create a new offer?"
            }
            content={
               isUpdateMode
                  ? "This action allows to update the changed last in this offer."
                  : "This action allows to create a new offer."
            }
            type="default"
            onConfirm={() => (isUpdateMode ? handleUpdateOffer() : handleCreateNewOffer())}
         >
            <div className={cx("btnSave")}>
               <SHrSosButton
                  loading={isPending || updateOfferIsPending}
                  buttonProps={{
                     className: cx("btn"),
                     disabled: !offerTypeSelected?.value || !termTemplate.termTemplateData || !pids.length,
                  }}
               >
                  {/* <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
                  </div> */}
                  {isUpdateMode ? "Edit" : "Create"} Offer
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>

         <SHrPopup show={isShowTermTemplateModal}>
            <OffersEditTermTemplateCreateOfferModal
               termTemplate={termTemplate}
               onClose={() => setIsShowTermTemplateModal(false)}
               onUpdateTermTemplate={() => setIsShowTermTemplateModal(false)}
               simplyTermsActions={{
                  onAddNew: handleAddNewSimplyTermsItem,
                  onUpdate: handleUpdateSimplyTermsItem,
                  onRemove: handleRemoveSimplyTermsItem,
               }}
               additionalTermsActions={{
                  onAddNew: handleAddNewAdditionalTermsItem,
                  onUpdate: handleUpdateAdditionalTermsItem,
                  onRemove: handleRemoveAdditionalTermsItem,
               }}
            />
         </SHrPopup>

         <SHrPopup show={isShowEditPropertiesModal}>
            <OffersAddPropertiesToCreateOfferModal
               onClose={() => setIsShowEditPropertiesModal(false)}
               pidsInit={pids}
               handleApplyProperties={(propertiesChanged) => handleApplyProperties(propertiesChanged)}
            />
         </SHrPopup>
      </div>
   );
};
