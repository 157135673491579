import classNames from "classnames/bind";
import { useState } from "react";
import { SHrSosButton } from "@simplyhomes/react";

import styles from "./SoSConvoCreateNewThreadModal.module.scss";

import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { useSoSOptions_Convo_Thread_Tags } from "../../../hooks/queries/options/useQSoS_Options";
import { useMConvo_CreateThread } from "../../../hooks/conversation-api/mutates/threads/useMConvo_CreateThread";
import { useToastContext } from "../../../contexts/ToastContext";
import { useQConvo_Threads } from "../../../hooks/conversation-api/queries/threads/useQConvo_Threads";
import { useAppContext } from "../../../contexts/AppContext";
import { Mention, MentionsInput } from "react-mentions";
import { useQ_Users } from "../../../hooks/queries/user/useQ_Users";
import { useM_Options_CreateOption } from "../../../hooks/mutates/options/useM_Options_CreateOption";

const cx = classNames.bind(styles);

export const SoSConvoCreateNewThreadModal = ({ onClose }: TSoSConvoCreateNewThreadModalProps) => {
   const { appState } = useAppContext();

   const { currentUser } = appState;

   const { name, roles, picture } = currentUser;

   const { toastDispatch } = useToastContext();
   const [title, setTitle] = useState<string>("");
   const [content, setContent] = useState<string>("");
   const qGetConvoTags = useSoSOptions_Convo_Thread_Tags();

   const mutateCreateNewTags = useM_Options_CreateOption();

   const [tagsSelect, setTagsSelect] = useState<string[]>([]);

   const qThreads = useQConvo_Threads();
   const qUsers = useQ_Users();

   const users = qUsers.data?.users.map((user) => ({ id: user.uid, display: user.name }));

   const { mutate, isPending } = useMConvo_CreateThread({
      onSuccess: () => {
         onClose();
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create new lead is successful!",
            },
         });
         qThreads.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create new lead is failed!",
            },
         }),
   });

   if (!qGetConvoTags.data?.options) return null;
   const tagOptions = qGetConvoTags.data?.options.map((o) => ({
      label: o.label,
      value: o.value,
   }));

   const handleCreateNewThread = () => mutate({ title, tags: tagsSelect, content: content });

   const handleCreateNewTags = (value: string) => {
      mutateCreateNewTags.mutate({
         value,
         label: value,
         moid: "sos_conversation_thread_tag",
      });
      qGetConvoTags.refetch();
   };

   return (
      <div className={cx("container")}>
         <div className={cx("heading")}>
            <div className={cx("userInfoC")}>
               <div className={cx("avt")}>
                  <img src={picture} alt="" />
               </div>
               <div className={cx("nameC")}>
                  <span>
                     <b>{name}</b>
                  </span>
                  <span className={cx("role")}>{roles?.map((r) => r).join(", ")}</span>
               </div>
            </div>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("content")}>
            <div className={cx("input_search")}>
               <SoSInputTitle
                  value={title}
                  type="text"
                  handleValue={(value) => setTitle(value)}
                  placeholder="Enter a subject"
               />
            </div>
            <div className={cx("message")}>
               <MentionsInput
                  placeholder="Start typing"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  className={cx("inputMentionC")}
               >
                  <Mention
                     data={users!}
                     trigger={"@"}
                     className={cx("mentionC")}
                     displayTransform={(id, display) => `@${display}`}
                  />
               </MentionsInput>
               {/* <textarea placeholder="" value={content} onChange={(e) => setContent(e.target.value)} /> */}
            </div>
            <div className={cx("keywordC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/local_offer_black.svg")} alt="" />
               </div>

               <div className={cx("input-select")}>
                  <SoSInputSelect
                     isMulti
                     value={tagOptions
                        .filter((o) => tagsSelect.includes(o.value))
                        .map((o) => ({ label: o.label, value: o.value }))}
                     onChange={(op) => setTagsSelect(op.map((v) => v.value))}
                     options={tagOptions}
                     allowCreate={{ cb: ({ value }) => handleCreateNewTags(value) }}
                  />
               </div>
            </div>
         </div>
         <SoSConfirmWrapper type="default" onConfirm={() => handleCreateNewThread()}>
            <div className={cx("btnSave")}>
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                     disabled: !title,
                  }}
                  loading={isPending}
               >
                  Post
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </div>
   );
};

type TSoSConvoCreateNewThreadModalProps = {
   onClose: () => void;
};
