import classNames from "classnames/bind";
import styles from "./PViAddress.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SoSCopyToClipboard } from "../../../../components/common/SoSCopyToClipboard/SoSCopyToClipboard";
import { useQPVi_Property } from "../../../../hooks/property-viability/queries/useQPVi_Property";
import { SHrSosButton } from "@simplyhomes/react";

const cx = classNames.bind(styles);

export const PViAddress = () => {
   const { property } = useQPVi_Property().data || {};

   if (!property) return;

   const handleOpenZillow = () => {
      const zillowUrl = `https://www.zillow.com/homes/${encodeURIComponent(property.address_one_line)}`;
      window.open(zillowUrl, "_blank");
   };

   return (
      <div className={cx("addressC", "_jr_pviAddress")}>
         <div className={cx("img-icon")}>
            <img src={CF_CDN_URL("/assets/property_validator/location_on_black.svg")} alt="" />
         </div>
         <span>{property.address_one_line}</span>
         <SoSCopyToClipboard value={property.address_one_line} placement="top-left" />
         <SHrSosButton type="outlined" buttonProps={{ className: cx("zillow-btn"), onClick: () => handleOpenZillow() }}>
            <img src={CF_CDN_URL("/assets/Zillow_icon.png")} alt="" className={cx("zillow-icon")} />
            Zillow
         </SHrSosButton>
      </div>
   );
};
