import { SignInLayout } from "../layouts/SignInLayout/SignInLayout";
import HomePage from "../pages/HomePage/HomePage";
import MainLayout from "../layouts/MainLayout/MainLayout";
import ApplicationLibraryPage from "../pages/ApplicationLibraryPage/ApplicationLibraryPage";
import { constants_paths } from "../constants/path";
import MapPage from "../pages/MapPage/MapPage";
import QuestionEditorPage from "../pages/QuestionEditorPage/QuestionEditorPage";
import { Dev } from "../pages/dev/Dev";
import CsvUploadPage from "../pages/CsvUploadPage/CsvUploadPage";
import FallBack from "../layouts/FallBack/FallBack";
import { PropertyViabilityPage } from "../pages/PropertyViabilityPage/PropertyViabilityPage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import version from "../version.json";
import { DevJim } from "../pages/devJim/DevJim";
import { DevNick } from "../pages/devNick/DevNick";
import { DevOcean } from "../pages/devOcean/DevOcean";
import { DevTimmy } from "../pages/devTimmy/DevTimmy";
import { OffersPage } from "../pages/OffersPage/OffersPage";
import { TransactionPage } from "../pages/TransactionPage/TransactionPage";
import { SignInPage } from "../pages/SignInPage/SignInPage";
export type RouteType = {
   path: string;
   page: (args: any) => React.ReactNode;
   layout: (...args: any[]) => React.ReactNode;
   appCode?: keyof typeof version;
};

export const publicRoutes: RouteType[] = [
   { path: constants_paths.SIGN_IN, page: SignInPage, layout: SignInLayout },
   { path: constants_paths.UNAUTHROIZED, page: () => <ErrorPage type={"Unauthorized"} />, layout: FallBack },
   { path: constants_paths.FORBIDDEN, page: () => <ErrorPage type={"Forbidden"} />, layout: FallBack },
   { path: constants_paths.SOMETHING_WRONG, page: () => <ErrorPage type={"InternalServerError"} />, layout: FallBack },
   { path: "*", page: () => <ErrorPage type={"NotFound"} />, layout: FallBack },
];
const genPrivateRoute = (path: string, page: () => React.ReactNode, appCode?: keyof typeof version) => ({
   path,
   page,
   layout: ({ ...args }: any) => <MainLayout {...args} appCode={appCode} />,
});
export const privateRoutes: RouteType[] = [
   genPrivateRoute(constants_paths.HOME, HomePage),
   genPrivateRoute(constants_paths.APPLICATION_LIBRARY, ApplicationLibraryPage),
   genPrivateRoute(constants_paths.OFFERS, OffersPage),
   genPrivateRoute(constants_paths.APPS, () => <></>),
   genPrivateRoute(constants_paths.APPS_MAP, MapPage, "Map"),
   genPrivateRoute(constants_paths.APPS_CSV_UPLOAD, CsvUploadPage, "CsvUp"),
   genPrivateRoute(constants_paths.APPS_QEDITOR, QuestionEditorPage, "QEditor"),
   genPrivateRoute(constants_paths.APPS_TRANSACTION, TransactionPage),
   genPrivateRoute(constants_paths.PROPERTY_VALIDATOR, PropertyViabilityPage),
   genPrivateRoute(constants_paths.DEV, Dev),
   genPrivateRoute(constants_paths.DEVJIM, DevJim),
   genPrivateRoute(constants_paths.DEVNICK, DevNick),
   genPrivateRoute(constants_paths.DEVOCEAN, DevOcean),
   genPrivateRoute(constants_paths.DEVTIMMY, DevTimmy),
];
