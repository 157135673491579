import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { usePViContext } from "../../../../contexts/PViContext";
import { useQPVi_Property } from "../../queries/useQPVi_Property";
import { useQPVi_Properties } from "../../queries/useQPVi_Properties";

export const useMPVi_UnderWritingApproval = () => {
   const { sosAxios } = useSosAxios();
   const { toastDispatch } = useToastContext();

   const { pid } = usePViContext().pviState;

   const qPVi_Property = useQPVi_Property();
   const qPVi_Properties = useQPVi_Properties();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "This underwriting is approved successfully!",
            },
         });
         qPVi_Property.refetch();
         qPVi_Properties.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "This underwriting is approved failed!",
            },
         }),
      mutationFn: () =>
         sosAxios
            .post(`/property-viability/properties/${pid}/underwriting-approved`)
            .then<PVI_UnderWritingApproval_Response>(({ data }: { data: PVI_UnderWritingApproval_Response }) => data),
   });
};

export type PVI_UnderWritingApproval_Response = {
   message: string;
};
