import { useEffect } from "react";
import { useWebSocketContext } from "../../contexts/WebSocketContext";
import { useWebSocket_CatchEvent } from "./useWebSocket_CatchEvent";

type useWebSocket_SubscribeChanges_Params<onChanges_Data = any> = {
   data: { type: "threads" | "thread"; id: string | number };
   onChanges: (data: onChanges_Data) => void;
};
export const useWebSocket_SubscribeChanges = <onChanges_Data>({
   data,
   onChanges,
}: useWebSocket_SubscribeChanges_Params<onChanges_Data>) => {
   const { socket } = useWebSocketContext();
   useEffect(() => {
      // console.log("useWebSocket_SubscribeChanges", data);
      if (!socket || !data.id) return;
      socket.emit("subscribe-changes", data);
      return () => {
         socket.emit("unsubscribe-changes", data);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // }, []);
   }, [data, socket]);
   useWebSocket_CatchEvent(`changes:${data.type}:${data.id}`, onChanges);
};
