import { SHrPopup } from "@simplyhomes/react";
import { usePViContext } from "../../../contexts/PViContext";
import { PViPropertyDetails } from "../PViPropertyDetails/PViPropertyDetails";
export const PViPropertyDetailsModal = () => {
   const { pviState, pviOverwrite } = usePViContext();

   const { showDetailsModal, pid } = pviState;

   return (
      <SHrPopup
         show={!!pid && showDetailsModal}
         handleShow={(b) => {
            pviOverwrite({ showDetailsModal: b, excludedPropertyIds: [], pid: b ? undefined : "" });
         }}
      >
         <PViPropertyDetails />
      </SHrPopup>
   );
};
