import { useQueryParams } from "../../react/useQueryParams";

export const useOffersQueryParams = () => {
   const { useMultiQueryParamsState } = useQueryParams();
   const [assignee, setAssigneeIds] = useMultiQueryParamsState("assignee");
   const [cids, setContactIds] = useMultiQueryParamsState("cids");

   const filters = { assignee, cids };
   return { filters, ...filters, setAssigneeIds, setContactIds };
};
