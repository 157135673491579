import classNames from "classnames/bind";
import styles from "./TransactionInsuranceTab.module.scss";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";

import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
import { useTransactionOptions } from "../../../hooks/transactions/hooks/useTransactionOptions";

const cx = classNames.bind(styles);

export const TransactionInsuranceTab = () => {
   const { transaction, property } = useSelectedTransactionAndProperty();

   const { qInspectionStatusOptions } = useTransactionOptions();

   if (!transaction) return null;

   const { properties } = transaction;

   const { insurance } = property || {};

   return (
      <div className={cx("container")}>
         <TransactionPropertySwitch properties={properties} />
         <div className={cx("itemC")}>
            <div className={cx("itemDetail", "isBackground")}>
               <SoSDataPoint type={"bold-value"} title="Monthly Cost" value={insurance?.cost} format={"currency"} />
            </div>
            <div className={cx("itemDetail")}>
               <SoSDataPoint type={"bold-title"} title="Insurance Status" value={insurance?.status} />
            </div>
            <div className={cx("itemDetail")}>
               <SoSDataPoint
                  type={"bold-title"}
                  title="Insurance Policy Number"
                  value={"National Real Estate Insurance Group (NREIG)"}
               />
               <SoSDataPoint type={"only-value"} title="Insurance Status" value={"https://nreig.com/"} />
            </div>
         </div>
         <div className={cx("insurance")}>
            <div className={cx("insuranceStatus")}>
               <SoSDataPoint type={"bold-title"} title="Insurance Provider" value={"HOM987654321"} />
               <SoSDataPoint type={"bold-title"} title="Choose a date" value={"Effective Date"} />
            </div>
            {/* <div className={cx("documentC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>Insurance Policy (documents)</b>
                  </span>
               </div>

               <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                  Upload a file
               </SHrSosButton>

               <div className={cx("linkC")}>
                  <div className={cx("linkUploadC")}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
                     </div>
                     <span>File name.pdf</span>
                     <div className={cx("linkIconsC")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                        </div>
                        <div className={cx("img-icon", "clear")}>
                           <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                        </div>
                     </div>
                  </div>
                  <div className={cx("linkUploadC")}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")} alt="" />
                     </div>
                     <span>File name.pdf</span>
                     <div className={cx("linkIconsC")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                        </div>
                        <div className={cx("img-icon", "clear")}>
                           <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div> */}
         </div>
      </div>
   );
};
