import classNames from "classnames/bind";
import styles from "./SoSUploaderContainer.module.scss";
// import { SoSUploaderWrapper } from "../../SoSUploaderWrapper/SoSUploaderWrapper";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
// import { SoSImageViewer } from "../../SoSImageViewer/SoSImageViewer";
import { useState } from "react";
import { useMDatabase_UpdateValue_Params } from "../../../hooks/database/mutations/useMDatabase_UpdateValue";

const cx = classNames.bind(styles);

export const SoSUploaderContainer = ({ type, database }: SoSUploaderContainerProps) => {
   const handleUploadFile = (fileList: FileList) => {};
   return <div>jjjj</div>;
   // return type === "files" ? (
   //    <div className={cx("container")}>
   //       <SoSUploaderWrapper type="files" onUpload={handleUploadFile} database={da}>
   //          <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
   //             Upload
   //          </SHrSosButton>
   //       </SoSUploaderWrapper>

   //       <div className={cx("linkC")}>
   //          {Array.from(files || []).map((file, index) => (
   //             <div key={index} className={cx("linkUploadC")}>
   //                <div className={cx("img-icon")}>
   //                   <img src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")} alt="" />
   //                </div>
   //                <span>{file.name}</span>
   //                <div className={cx("linkIconsC")}>
   //                   <div className={cx("img-icon")}>
   //                      <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
   //                   </div>
   //                   <div className={cx("img-icon", "clear")}>
   //                      <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
   //                   </div>
   //                </div>
   //             </div>
   //          ))}
   //       </div>
   //    </div>
   // ) : (
   //    <div className={cx("container")}>
   //       <div className={cx("linkC")}>
   //          <SoSImageViewer images={files ? Array.from(files).map((file) => URL.createObjectURL(file)) : []} />
   //       </div>
   //       <SoSUploaderWrapper type="images" onUploaded={(iuids: string[]) => console.log(iuids)}>
   //          <SHrSosButton type="text" buttonProps={{ className: cx("uploadImgBtn") }} loading={false}>
   //             <div className={cx("img-icon")}>
   //                <img src={CF_CDN_URL("/assets/add_photo_alternate_black.svg")} alt="" />
   //             </div>
   //          </SHrSosButton>
   //       </SoSUploaderWrapper>
   //    </div>
   // );
};

type SoSUploaderContainerProps = {
   type: "images" | "files";
   database: useMDatabase_UpdateValue_Params;
};
