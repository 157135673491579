import classNames from "classnames/bind";
import styles from "./TransactionPropertiesTab.module.scss";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { OffersCreateNewContactModal } from "../../OffersPage/OffersCreateNewContactModal/OffersCreateNewContactModal";
import { useState } from "react";
import { PViMediaSlider } from "../../PropertyViabilityPage/PViPropertyDetails/PViMediaSlider/PViMediaSlider";
import { useToastContext } from "../../../contexts/ToastContext";
import { useMSoS_Associations_CreateOne } from "../../../hooks/associations/mutates/useMSoS_Associations_CreateOne";
import { useMSoS_Associations_DeteleOne } from "../../../hooks/associations/mutates/useMSoS_Associations_DeteleOne";
import { TPgSoS_SoSUtilitiesAllowances_Schema_Data } from "@simplyhomes/utils/dist/types/pg/sos/utilities-allowances";
import { TPgSoS_SoSUnits_Schema_UtilitiesConfig } from "@simplyhomes/utils/dist/types/pg/sos/units";
import { TSoSInputSelect_option } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { useMDatabase_UpdateValue } from "../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";

const cx = classNames.bind(styles);

export const TransactionPropertiesTab = () => {
   const { toastDispatch } = useToastContext();

   const { transaction, property, qGetTransaction } = useSelectedTransactionAndProperty();

   const { mutate: mutateCreatePropertyContact, isPending: isPendingCreatePropertyContact } =
      useMSoS_Associations_CreateOne({
         onSuccess: () => {
            qGetTransaction?.refetch();
         },
      });

   const { mutate: mutateDeletePropertyContact, isPending: isPendingDeletePropertyContact } =
      useMSoS_Associations_DeteleOne({
         onSuccess: () => {
            qGetTransaction?.refetch();
         },
      });

   const [isOpenAddContactModal, setIsOpenAddContactModal] = useState(false);

   if (!transaction) return null;

   const { properties } = transaction;

   const { contacts, pid, units } = property || {};

   const handleCreatePropertyContact = (cid: number) => {
      const cidExist = contacts?.find((c) => c.cid === cid);
      if (cidExist) {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "warning",
               message: "This contact is already exist!",
            },
         });
         return;
      }
      mutateCreatePropertyContact({ table: "sos_rel_contacts_properties", records: [{ pid, cid }] });
   };

   const handleRemovePropertyContact = (cid: number) => {
      mutateDeletePropertyContact({ table: "sos_rel_contacts_properties", records: [{ pid, cid }] });
   };

   return (
      <div className={cx("container")}>
         <TransactionPropertySwitch properties={properties} />

         <div className={cx("mediaSliderC")}>
            <PViMediaSlider />
         </div>

         <div className={cx("sourceC")}>
            <span>Source</span>
            <div className={cx("source")}>
               <div className={cx("sourceDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Seller Name" value={"Kelly Finland"} isMocked />
               </div>
               <div className={cx("sourceDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Seller Phone" value={"(000) 000 0000"} isMocked />
               </div>
               <div className={cx("sourceDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Seller Email" value={"kelly.f@gmail.com"} isMocked />
               </div>
            </div>
            <div className={cx("source")}>
               <div className={cx("sourceDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Date Created" value={"12 May 2023"} isMocked />
               </div>
               <div className={cx("sourceDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Source Type" value={"DTS"} isMocked />
               </div>
            </div>
         </div>
         <hr className={cx("divide")} />

         <div className={cx("contactC")}>
            <div className={cx("contactTitle")}>
               <span>Additional Contacts</span>
               <SHrSosButton
                  type="text"
                  loading={isPendingCreatePropertyContact}
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setIsOpenAddContactModal(true),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                  </div>
                  New contact
               </SHrSosButton>
            </div>

            {!contacts?.length ? (
               <div className={cx("noContact")}>No property's contact found</div>
            ) : (
               contacts?.map((contact, i) => (
                  <div className={cx("contact")}>
                     <div className={cx("contactDetail")}>
                        <SoSDataPoint type={"bold-title"} title="Name" value={contact.name} />
                        {/* <SoSDataPoint type={"only-value"} value={"Realtor"}  /> */}
                     </div>

                     <div className={cx("contactDetail")} key={i}>
                        <SoSDataPoint type={"bold-title"} title="Phone" value={contact.phone_primary} />
                     </div>

                     <div className={cx("contactDetail")}>
                        <SoSDataPoint type={"bold-title"} title="Email" value={contact.email} />
                     </div>
                     {isPendingDeletePropertyContact ? (
                        <SHrSpinner />
                     ) : (
                        <div className={cx("img-icon")} onClick={() => handleRemovePropertyContact(contact.cid)}>
                           <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                        </div>
                     )}
                  </div>
               ))
            )}
         </div>

         <hr className={cx("divide")} />

         <div className={cx("itemC")}>
            <div className={cx("itemDetail")}>
               <SoSDataPoint type={"bold-title"} title="Neighborhood" value={"Select a neighborhood"} isMocked />
            </div>
            <div className={cx("itemDetail")}>
               <SoSDataPoint type={"bold-title"} title="Market" value={"Select a market"} isMocked />
            </div>
            <div className={cx("itemDetail")}>
               <SoSDataPoint type={"bold-title"} title="ZIP code" value={"Select a ZIP code"} isMocked />
            </div>
         </div>
         <div className={cx("itemC")}>
            <div className={cx("itemDetail")}>
               <SoSDataPoint type={"bold-title"} title="Square feet" icon={"sqft"} value={"1,000 sqft"} isMocked />
            </div>
            <div className={cx("itemDetail")}>
               <SoSDataPoint
                  type={"bold-title"}
                  title="Housing Authority"
                  value={"Public Housing Authority (PHA)"}
                  isMocked
               />
            </div>
            <div className={cx("itemDetail")}>
               <SoSDataPoint
                  type={"bold-title"}
                  title="Property Manager"
                  value={"Select a Property manager"}
                  isMocked
               />
            </div>
         </div>

         <hr className={cx("divide")} />

         {units?.map((_, i) => (
            <TransactionUnit index={i} key={i} />
         ))}

         <SHrPopup show={isOpenAddContactModal}>
            <OffersCreateNewContactModal
               onClose={() => setIsOpenAddContactModal(false)}
               onSelection={handleCreatePropertyContact}
            />
         </SHrPopup>
      </div>
   );
};

const TransactionUnit = ({ index }: { index: number }) => {
   const { property, qGetTransaction } = useSelectedTransactionAndProperty();

   const { units, utilities_allowances } = property || {};

   const unit = units?.[index];

   const db_U = dbcb("sos_units", [{ column: "uid", id: unit?.uid || "" }], () => qGetTransaction?.refetch());

   const mutateDB = useMDatabase_UpdateValue(db_U("utilities_config"));

   if (!unit) return null;

   const handleSetConfig = (config: TPgSoS_SoSUnits_Schema_UtilitiesConfig) => {
      mutateDB.mutate({ value: JSON.stringify(config) });
   };

   return (
      <div className={cx("unitDetailC")} key={index}>
         <span>
            <b>Unit {index + 1}</b>
         </span>
         <div className={cx("unitDataDetailC")}>
            <div className={cx("unitDataHeadingC")}>
               <div className={cx("dot")}></div>
               <span>
                  <b>Rooms & Stories</b>
               </span>
            </div>
            <div className={cx("unitDataC")}>
               <SoSDataPoint
                  title="Bedrooms"
                  type="bold-title"
                  value={unit.beds > 1 ? `${unit.beds} beds` : `${unit.beds} beds`}
                  icon="bedroom"
               />
               <SoSDataPoint
                  title="Bathrooms"
                  type="bold-title"
                  value={unit.baths > 1 ? `${unit.baths} baths` : `${unit.baths} bath`}
                  icon="bathroom"
               />
               <SoSDataPoint title="Stories" type="bold-title" value={"1 story"} icon="height" isMocked />
            </div>
         </div>

         <div className={cx("unitDataDetailC")}>
            <div className={cx("unitDataHeadingC")}>
               <div className={cx("dot")}></div>
               <span>
                  <b>Leasing</b>
               </span>
            </div>
            <div className={cx("leasingC")}>
               <div className={cx("leasing")}>
                  <SoSDataPoint title="Current Lease" type="bold-title" value={"Status?"} isMocked />
                  <SoSDataPoint
                     title="Current Lease Rent"
                     type="bold-title"
                     value={1500}
                     format={"currency"}
                     isMocked
                  />
                  <SoSDataPoint title="Current Lease Type" type="bold-title" value={"type"} isMocked />
                  <SoSDataPoint title="Current Lease End" type="bold-title" value={"Date?"} isMocked />
               </div>
               {/* <div className={cx("documentC")}>
         <div className={cx("headingC")}>
            <span>
               <b>Lease Document</b>
            </span>
         </div>

         <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
            Upload a file
         </SHrSosButton>

         <div className={cx("linkC")}>
            <div className={cx("linkUploadC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
               </div>
               <span>File name.pdf</span>
               <div className={cx("linkIconsC")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                  </div>
                  <div className={cx("img-icon", "clear")}>
                     <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                  </div>
               </div>
            </div>
            <div className={cx("linkUploadC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")} alt="" />
               </div>
               <span>File name.pdf</span>
               <div className={cx("linkIconsC")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                  </div>
                  <div className={cx("img-icon", "clear")}>
                     <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                  </div>
               </div>
            </div>
         </div>
      </div> */}
            </div>
         </div>

         <div className={cx("unitDataDetailC")}>
            <div className={cx("unitDataHeadingC")}>
               <div className={cx("dot")}></div>
               <span>
                  <b>Utility</b>
               </span>
            </div>
            <div className={cx("unitDataC")}>
               {utilities_allowances &&
                  Object.entries(utilities_allowances).map(([groupTitle, groupData], i) => (
                     <UtilitiesGroup
                        groupData={groupData}
                        groupTitle={groupTitle}
                        config={unit.utilities_config}
                        key={groupTitle + i}
                        index={i}
                        setConfig={(c) => handleSetConfig(c)}
                     />
                  ))}
               {/* <SoSDataPoint title="Heating Type" type="bold-title" value={"Type"} isMocked />
         <SoSDataPoint title="Water Heating Type" type="bold-title" value={"Type"} isMocked />
         <SoSDataPoint title="Cooking Type" type="bold-title" value={"Type"} isMocked /> */}
            </div>
         </div>
      </div>
   );
};

type UtilitiesGroupParams = {
   groupTitle: string;
   groupData: TPgSoS_SoSUtilitiesAllowances_Schema_Data[string];
   config: TPgSoS_SoSUnits_Schema_UtilitiesConfig;
   setConfig: (c: TPgSoS_SoSUnits_Schema_UtilitiesConfig) => void;
   index: number;
};
const UtilitiesGroup = ({ groupData, groupTitle, config, setConfig, index }: UtilitiesGroupParams) => {
   if (!groupData) return null;
   const { lineItems, isMultiSelect } = groupData;
   const value = config.filter((o) => o.groupTitle === groupTitle).map((o) => o.lineTitle);
   const handleSetConfig = (o: TSoSInputSelect_option[]) => {
      const Options = o.length === 0 ? o : isMultiSelect ? o : [o[o.length - 1]];
      const newConfig = [
         ...config.filter((c) => c.groupTitle !== groupTitle),
         ...Options.map((o) => ({ lineTitle: o.value, groupTitle })),
      ];
      setConfig(newConfig);
   };
   return (
      <SoSDataPoint
         key={index}
         title={groupTitle}
         type="bold-title"
         options_isMulti
         value={value}
         options={lineItems.map((o) => o.title) || []}
         onOptionSelect={(o) => handleSetConfig(o)}
      />
   );
};
