import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../contexts/ToastContext";
import { useSosAxios } from "../../axios/useSosAxios";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useQTrans_Transaction } from "../queries/useQTrans_Transaction";

export const useMTrans_ScheduleInspection = () => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const { oid, pidSelected } = useTransactionsContext().transactionsState;

   const qGetTransaction = useQTrans_Transaction();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Schedule Inspection is successful!",
            },
         });
         qGetTransaction.refetch();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Schedule Inspection is failed!",
            },
         });
      },

      mutationFn: (createBody: Transactions_ScheduleInspection_Body) =>
         sosAxios
            .post(`/transactions/${oid}/properties/${pidSelected}/inspections`, { ...createBody })
            .then<Transactions_CreateInspection_Response>(({ data }) => data),
   });
};

export type Transactions_CreateInspection_Response = {
   message: string;
};
export type Transactions_ScheduleInspection_Body = {
   inspection_date: string;
   reno_person: string;
   assigned_poc: string;
   current_occupancy?: string;
   access_details?: string;
   notes?: string;
};
