import classNames from "classnames/bind";
import styles from "./OffersLeadsList.module.scss";

import { OffersLeadCard } from "../OffersLeadCard/OffersLeadCard";
import { OffersSettingsTerm } from "../OffersSettingsTerm/OffersSettingsTerm";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useOffersQueryParams } from "../../../hooks/offers/hooks/useOffersQueryParams";
import { useQOffers_GetLeads } from "../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { OffersFilterByPeopleModal } from "../OffersFilterByPeopleModal/OffersFilterByPeopleModal";
import { useState } from "react";
import { useSoSOptions_Offers_Leads_Statuses } from "../../../hooks/queries/options/useQSoS_Options";
const cx = classNames.bind(styles);

export const OffersLeadsList = () => {
   const qOfferLeads = useQOffers_GetLeads();

   const qOpLeadStatuses = useSoSOptions_Offers_Leads_Statuses();
   const leadStatuses = qOpLeadStatuses.data?.options || [];

   const qpOfferLeads = useOffersQueryParams();
   const { assignee, cids } = qpOfferLeads;

   const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
   const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
   const people = (assignee.length || 0) + (cids.length || 0);
   const handleToggleStatus = (statusValue: string) =>
      setSelectedStatus(selectedStatus === statusValue ? null : statusValue);
   return (
      <div className={cx("leftC")}>
         <OffersSettingsTerm />
         <div className={cx("leftBodyC")}>
            <div className={cx("filterTopC", "_jr_offersLeadsFilters")}>
               <div className={cx("filterAssignee")}>
                  <SHrSosButton type="outlined" buttonProps={{ onClick: () => setIsOpenFilterModal(true) }}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/person_search.svg")} alt="" />
                     </div>
                     People ({people})
                  </SHrSosButton>
               </div>
            </div>

            <div className={cx("leadsListC", "_jr_offersLeadsList")}>
               {qOfferLeads.isLoading && qOfferLeads.isFetching ? (
                  <div className={cx("spinnerC")}>
                     <SHrSpinner />
                     <span>Loading Leads...</span>
                  </div>
               ) : qOfferLeads.data?.leads.length ? (
                  [...leadStatuses, { value: "Others", label: "Others" }].map((o, i) => (
                     <StatusGroup
                        key={i}
                        statusValue={o.value}
                        statusLabel={o.label}
                        isOpen={selectedStatus === o.value}
                        onToggle={() => handleToggleStatus(o.value)}
                        color={o.color}
                     />
                  ))
               ) : (
                  <div className={cx("noDataC")}>No lead matching filter!</div>
               )}
            </div>
         </div>

         <SHrPopup show={isOpenFilterModal}>
            <OffersFilterByPeopleModal onClose={() => setIsOpenFilterModal(false)} />
         </SHrPopup>
      </div>
   );
};

type StatusGroupProps = {
   statusValue: string;
   statusLabel: string;
   isOpen: boolean;
   onToggle: () => void;
   color?: string;
};
const StatusGroup = ({ statusValue, statusLabel, isOpen, onToggle, color }: StatusGroupProps) => {
   const qLeads = useQOffers_GetLeads();
   const { leadsGroupedByStatus = {} } = qLeads.data || {};
   const leads = leadsGroupedByStatus[statusValue] || [];
   return (
      <div className={`${styles.statusGroup} ${isOpen && styles.isOpen}`}>
         <div onClick={onToggle}>
            <img src={CF_CDN_URL("/assets/offer/expand_more.svg")} alt="" />
            <div>
               <span style={{ backgroundColor: color }}>{statusLabel}</span>
               <span> ({leads.length})</span>
            </div>
         </div>
         {isOpen && (
            <ul>
               {leads.map(({ lid }) => (
                  <li key={lid}>
                     <OffersLeadCard lid={lid} />
                  </li>
               ))}
            </ul>
         )}
      </div>
   );
};
