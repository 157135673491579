import { useEffect } from "react";
import { useWebSocketContext } from "../../contexts/WebSocketContext";

export const useWebSocket_CatchEvent = <Data>(event: string, callback: (data: Data) => void) => {
   const { socket } = useWebSocketContext();
   useEffect(() => {
      if (!socket) return;
      socket.on(event, (d) => {
         // console.log("useWebSocket_CatchEvent", event, d);
         callback(d);
      });
      return () => {
         socket.off(event);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // }, []);
   }, [callback, event, socket]);
};
