import { createContext, useContext, useReducer } from "react";

class TransactionsState {
   showDetailsModal: boolean = false;
   oid: number = 0;
   pidSelected: string = "";
}
type TTransactionsOverwrite = Partial<TransactionsState>;
type TTransactionsAction = { overwrite: TTransactionsOverwrite };
export const TransactionsContext = createContext<{
   transactionsState: TransactionsState;
   transactionsDispatch: React.Dispatch<TTransactionsAction>;
}>({
   transactionsState: new TransactionsState(),
   transactionsDispatch: () => {},
});
const transactionsReducer = (state: TransactionsState, action: TTransactionsAction): TransactionsState => {
   if ("overwrite" in action) {
      return { ...state, ...action.overwrite };
   }
   return state;
};

export const TransactionsProvider = ({ children }: { children: React.ReactNode }) => {
   const [transactionsState, transactionsDispatch] = useReducer(transactionsReducer, new TransactionsState());

   return (
      <TransactionsContext.Provider value={{ transactionsState, transactionsDispatch }}>
         {children}
      </TransactionsContext.Provider>
   );
};

export const useTransactionsContext = () => {
   const context = useContext(TransactionsContext);
   const transactionsOverwrite = (overwrite: TTransactionsOverwrite) => context.transactionsDispatch({ overwrite });
   return { ...context, transactionsOverwrite };
};
