import classNames from "classnames/bind";
import styles from "./TransactionFinancingTab.module.scss";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";

const cx = classNames.bind(styles);

export const TransactionFinancingTab = () => {
   const { transaction } = useSelectedTransactionAndProperty();

   if (!transaction) return null;

   const { properties } = transaction;

   return (
      <div className={cx("container")}>
         <TransactionPropertySwitch properties={properties} />
         <div className={cx("financingC")}>
            <span>(Activity Name)</span>
            <div className={cx("financing")}>
               <div className={cx("financingStatus")}>
                  <SoSDataPoint type={"bold-title"} title="Financing Status" value={"Requested"} />
               </div>
               {/* <hr className={cx("divide")} />
               <div className={cx("documentC")}>
                  <div className={cx("headingC")}>
                     <span>
                        <b>Related Document</b>
                     </span>
                  </div>

                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>

                  <div className={cx("linkC")}>
                     <div className={cx("linkUploadC")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
                        </div>
                        <span>File name.pdf</span>
                        <div className={cx("linkIconsC")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                           </div>
                           <div className={cx("img-icon", "clear")}>
                              <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                           </div>
                        </div>
                     </div>
                     <div className={cx("linkUploadC")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")} alt="" />
                        </div>
                        <span>File name.pdf</span>
                        <div className={cx("linkIconsC")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                           </div>
                           <div className={cx("img-icon", "clear")}>
                              <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}
            </div>
         </div>
         <hr />
         <div className={cx("financingC")}>
            <span>Acquisition</span>
            <div className={cx("financing")}>
               <div className={cx("financingStatus")}>
                  <SoSDataPoint type={"bold-title"} title="Acquisition Status" value={"(status)"} />
               </div>
               {/* <hr className={cx("divide")} />
               <div className={cx("documentC")}>
                  <div className={cx("headingC")}>
                     <span>
                        <b>Related Document</b>
                     </span>
                  </div>

                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>

                  <div className={cx("linkC")}>
                     <div className={cx("linkUploadC")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
                        </div>
                        <span>File name.pdf</span>
                        <div className={cx("linkIconsC")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                           </div>
                           <div className={cx("img-icon", "clear")}>
                              <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                           </div>
                        </div>
                     </div>
                     <div className={cx("linkUploadC")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")} alt="" />
                        </div>
                        <span>File name.pdf</span>
                        <div className={cx("linkIconsC")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                           </div>
                           <div className={cx("img-icon", "clear")}>
                              <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}
            </div>
         </div>
      </div>
   );
};
