import classNames from "classnames/bind";
import styles from "./TransactionUnderWritingTab.module.scss";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { PViUnderwriting } from "../../PropertyViabilityPage/PViPropertyDetails/PViPropertyDetailsTabs/PViUnderwriting/PViUnderwriting";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
const cx = classNames.bind(styles);
export const TransactionUnderWritingTab = () => {
   const { transaction, pidSelected } = useSelectedTransactionAndProperty();

   if (!transaction) return null;

   const { properties } = transaction;

   return (
      <div className={cx("container")}>
         <TransactionPropertySwitch properties={properties} />
         <PViUnderwriting pid={pidSelected} />
      </div>
   );
};
