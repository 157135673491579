import { CredentialResponse, GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";

import styles from "./SignInPage.module.scss";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { useAppContext } from "../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/react/useLocalStorage";
import { useSosAuth } from "../../hooks/auth/useSosAuth";

export const SignInPage = () => {
   const nav = useNavigate();
   const { appDispatch } = useAppContext();
   // const { set: setLocalCredential } = useLocalStorage("sos_credential", "");
   // const handleSuccess = ({ credential }: CredentialResponse) => {
   //    if (!credential) return;
   //    appDispatch({ overwrite: { credential } });
   //    setLocalCredential(credential);
   //    nav("/");
   // };
   const { logIn } = useSosAuth();
   useGoogleOneTapLogin({ onSuccess: logIn, onError: () => console.log("errored") });

   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.imgC}`}>
            <div></div>
            <img src={CF_CDN_URL("/assets/sign_in_background.png")} alt="" />
         </div>
         <div className={`${styles.loginC}`}>
            <header className={`${styles.top}`}>
               <img src={CF_CDN_URL("/assets/logo_dark.svg")} alt="" />
               <span>Simply OS</span>
            </header>
            <div className={`${styles.center}`}>
               <h1>Welcome to Simply OS!</h1>
               <p>Please use your simplyhomes.com email address that is already registered on this platform.</p>
               <GoogleLogin onSuccess={logIn} onError={() => console.log("erroed")} />
            </div>
            <div></div>
         </div>
      </section>
   );
};

