import { ReactNode } from "react";
import { Route, Routes } from "react-router-dom";

import "react-calendar-timeline/lib/Timeline.css";
import "./App.scss";
import { RouteType, privateRoutes, publicRoutes } from "./routes";
import MainLayout from "./layouts/MainLayout/MainLayout";
import { SignInPage } from "./pages/SignInPage/SignInPage";
import { useAppContext } from "./contexts/AppContext";
import { useGoogleMapsApiLoader } from "./hooks/google-maps-api/useGoogleMapsApiLoader";

const getRoutes = (routes: RouteType[]): ReactNode => {
   return routes.map((route) => {
      const Layout = route.layout! || MainLayout;
      const Page = route.page!;
      return (
         <Route
            key={route.path}
            path={route.path}
            element={
               <Layout>
                  <Page />
               </Layout>
            }
         ></Route>
      );
   });
};
const App = () => {
   const { credential } = useAppContext().appState;
   const { isLoaded } = useGoogleMapsApiLoader();
   if (!isLoaded) return null;
   return (
      <div className="AppC">
         <Routes>
            {!credential ? <Route path="*" element={<SignInPage />} /> : getRoutes([...privateRoutes, ...publicRoutes])}
         </Routes>
      </div>
   );
};

export default App;
