import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { SoS_TermTemplate } from "../../queries/termTemplate/useQOffers_GetTermTemplateById";
import { useQOffers_Lead } from "../../queries/leads/useQOffers_GetLead";
import { useQOffers_GetLeads } from "../../queries/leads/useQOffers_GetLeads";
import { useToastContext } from "../../../../contexts/ToastContext";

export const useMOffers_UpdateOffer = () => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const qOffersGetLead = useQOffers_Lead();

   const qOffersGetLeads = useQOffers_GetLeads();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Edit offer is successful!",
            },
         });
         qOffersGetLead.refetch();
         qOffersGetLeads.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Edit offer is failed!",
            },
         }),
      mutationFn: ({ oid, ...updateOfferProps }: TSoS_Offers_UpdateOffer_Props) =>
         sosAxios.patch(`/offers/${oid}`, updateOfferProps).then<TSoS_Offers_UpdateOffer_Response>(({ data }) => {
            return data;
         }),
   });
};

type SoS_Offers_UpdateOffer_Terms = SoS_TermTemplate;

type TSoS_Offers_UpdateOffer_Props = {
   oid: number;
   status?: string;
   type?: string;
   pids?: string[];
   terms?: SoS_Offers_UpdateOffer_Terms;
};

type TSoS_Offers_UpdateOffer_Response = { message: string };
