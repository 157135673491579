import classNames from "classnames/bind";
import styles from "./TransactionDocumentTab.module.scss";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { SoSUploaderWrapper } from "../../../components/SoSUploaderWrapper/SoSUploaderWrapper";
import { useMDatabase_UploadFiles } from "../../../hooks/database/mutations/useMDatabase_UploadFiles";
import { useMDatabase_UploadImages } from "../../../hooks/database/mutations/useMDatabase_UploadImages";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSUploaderContainer } from "../../../components/common/SoSUploaderContainer/SoSUploaderContainer";

const cx = classNames.bind(styles);

export const TransactionDocumentTab = () => {
   const db_P = dbcb("sos_properties", [{ column: "pid", id: "ChIJ1TYiDHPQNIgRTDK3xPmzJfw" }]);
   // const mUploadFiles = useMDatabase_UploadFiles(db_P("scope_of_work"));
   // const mUploadImages = useMDatabase_UploadImages(db_P("scope_of_work"));
   return (
      <div className={cx("container")} style={{ width: "100%" }}>
         <TransactionPropertySwitch properties={[]} />
         {/* <SoSUploaderWrapper type="images" onUploaded={(iuids: string[]) => console.log(iuids)}>
            <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }} loading={false}>
               Upload
            </SHrSosButton>
         </SoSUploaderWrapper> */}
         <SoSUploaderContainer type="files" database={db_P("scope_of_work")} />
      </div>
   );
};
