import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useQTrans_Transaction } from "../queries/useQTrans_Transaction";

export const useSelectedTransactionAndProperty = () => {
   const { pidSelected } = useTransactionsContext().transactionsState;

   const qGetTransaction = useQTrans_Transaction();

   const transaction = qGetTransaction.data?.transaction;

   if (!transaction) return { property: null, transaction: null };

   const { properties } = transaction;

   const property = properties.find((p) => p.pid === pidSelected);

   return { property, transaction, pidSelected, qGetTransaction };
};
