import { TableColumn, TableStyles } from "react-data-table-component";
import { TransactionStatus } from "./TransactionListView";
import classNames from "classnames/bind";
import styles from "./TransactionListView.module.scss";
import { Transactions_GetTransactions_Transaction } from "../../../hooks/transactions/queries/useQTrans_Transactions";
const cx = classNames.bind(styles);
export const allTransactionColumn: TableColumn<Transactions_GetTransactions_Transaction>[] = [
   { name: <strong>Transaction Name</strong>, selector: (data) => data.name, width: "400px" },
   {
      name: <strong>EMD Status</strong>,
      cell: ({ emd_status }: Transactions_GetTransactions_Transaction) => {
         return <TransactionStatus EMDStatus={emd_status} />;
      },
   },
   {
      name: <strong>Inspection Status</strong>,
      cell: ({ inspection }: Transactions_GetTransactions_Transaction) => {
         return <TransactionStatus inspectionStatus={inspection} />;
      },
   },
   {
      name: <strong>Title Status</strong>,
      cell: ({ title_status }: Transactions_GetTransactions_Transaction) => {
         return <TransactionStatus titleStatus={title_status} />;
      },
   },
   {
      name: <strong>Finaning Status</strong>,
      cell: ({ financing_status }: Transactions_GetTransactions_Transaction) => {
         return <TransactionStatus financingStatus={financing_status} />;
      },
   },
   {
      name: <strong>Closing Status</strong>,
      cell: ({ closing_status }: Transactions_GetTransactions_Transaction) => {
         return <TransactionStatus closingStatus={closing_status} />;
      },
   },
   { name: <strong>Market</strong>, selector: (data) => data.market_name },
   { name: <strong>Team Lead</strong>, selector: (data) => "mocked" },
   { name: <strong>Coordinator</strong>, selector: (data) => "mocked" },
];

export const customStyles: TableStyles = {
   headCells: {
      style: {
         borderRight: "solid 1px #D4D5D9",
         fontSize: "16px",
         background: "var(--primary-50, #E2EBF3)",
         position: "sticky",
         left: 0,

         "&:first-child": {
            borderRightWidth: "2px",
            zIndex: 2,
         },
      },
   },
   headRow: {
      style: {
         borderBottom: "none",
      },
   },
   cells: {
      style: {
         borderRight: "solid 1px #D4D5D9",
         fontSize: "16px",
         width: "140px",
         "&:first-child": {
            position: "sticky",
            left: 0,
            backgroundColor: "white",
            zIndex: 1,
         },
      },
   },
};