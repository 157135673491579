import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useQOffers_Lead } from "../../queries/leads/useQOffers_GetLead";

export const useMOffers_DeleteOffer = () => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const qGetLead = useQOffers_Lead();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "This offers is deleted successful!",
            },
         });
         qGetLead.refetch();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "This offers is deleted failed!",
            },
         });
      },

      mutationFn: ({ oid }: SoS_Offers_DeleteOffer_Param) =>
         sosAxios.delete(`/offers/${oid}`).then<SoS_Offers_DeleteOffer_Response>(({ data }) => data),
   });
};

export type SoS_Offers_DeleteOffer_Param = {
   oid: number;
};

export type SoS_Offers_DeleteOffer_Response = {
   message: string;
};
