import { useQueryParams } from "../../react/useQueryParams";

export const useTransQueryParams = () => {
   const { useSingleQueryParamsState } = useQueryParams();
   const [limit] = useSingleQueryParamsState("limit", "50");

   const filters = { limit };

   return {
      ...filters,
      filters,
   };
};
