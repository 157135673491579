import classNames from "classnames/bind";
import styles from "./PViTaxHistory.module.scss";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { PViDataPoint } from "../../../../../components/PViDataPoint/PViDataPoint";

const cx = classNames.bind(styles);

export const PViTaxHistory = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p } = qPVi_Property.data || {};
   if (!p) return null;

   return (
      <div className={cx("container")}>
         <div className={cx("infoC")}>
            <PViDataPoint type="bold-title" value={p.tax_data?.apn} title="APN" />
            <PViDataPoint type="bold-title" value={p.tax_data?.fips} title="Fips" />
            <PViDataPoint type="bold-title" value={p.tax_data?.assdlandvalue} title="Assessed Land Value" />
         </div>
         <div className={cx("infoC")}>
            <PViDataPoint type="bold-title" value={p.tax_data?.assdimprvalue} title="Assessed Improvement Value" />
            <PViDataPoint type="bold-title" value={p.tax_data?.assdttlvalue} title="Assessed Total Value" />
         </div>
      </div>
   );
};
