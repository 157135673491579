import Timeline, {
   DateHeader,
   SidebarHeader,
   TimelineGroupBase,
   TimelineHeaders,
   TimelineItemBase,
} from "react-calendar-timeline";
import styles from "./DevTimmy.module.scss";
import moment from "moment";
export const DevTimmy = () => {
   return (
      <div className={`${styles.contentC}`}>
         <TestTable />
      </div>
   );
};

const TestTable = () => {
   const groups: TimelineGroupBase[] = [
      { id: 1, title: "group 1" },
      { id: 2, title: "group 2" },
      { id: 3, title: "group 2" },
      { id: 4, title: "group 2" },
   ];
   // const defaultTimeStart = moment().add(-1, "month").startOf("month").toDate();
   // const defaultTimeEnd = moment().add(1, "month").startOf("month").toDate();
   const defaultTimeStart = moment().startOf("month");
   const defaultTimeEnd = moment().endOf("month");
   const items: TimelineItemBase<moment.Moment>[] = [
      {
         id: 1,
         group: 54,
         title: "item 1",
         start_time: moment(),
         end_time: moment().add(1, "day"),
      },
      {
         id: 2,
         group: 57,
         title: "item 2",
         start_time: moment().add(-0.5, "day"),
         end_time: moment().add(0.5, "day"),
      },
      {
         id: 3,
         group: 60,
         title: "item 3",
         start_time: moment().add(2, "day"),
         end_time: moment().add(3, "day"),
      },
   ];
   return (
      <div className={`${styles.testTable}`}>
         <Timeline
            stackItems
            groups={groups}
            items={items}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            className={`${styles.timeline}`}
            minZoom={1000 * 60 * 60 * 24 * 30}
            minResizeWidth={74}
            lineHeight={128}
            itemHeightRatio={0.25}
            sidebarWidth={320}
            useResizeHandle
            itemRenderer={customItemRenderer}
            groupRenderer={customGroupRenderer}
         >
            <TimelineHeaders className="sticky">
               <SidebarHeader>
                  {({ getRootProps }) => {
                     return (
                        <div
                           {...getRootProps({
                              style: {
                                 borderRight: "1px solid #bbb",
                              },
                           })}
                        >
                           Active contracts
                        </div>
                     );
                  }}
               </SidebarHeader>
               <DateHeader unit="day" labelFormat="ddd" />
               <DateHeader unit="day" labelFormat="DD" />
            </TimelineHeaders>
         </Timeline>
      </div>
   );
};

const customItemRenderer = ({ item, itemContext, getItemProps, getResizeProps }: any) => {
   const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
   return (
      <div
         {...getItemProps({
            style: {
               borderRadius: "8px",
               border: "1px solid #ddd",
               backgroundColor: itemContext.selected ? "#A2ECC2" : "#A2ECC2",
               color: "#A2ECC2",
               // padding: "4px",
               boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            },
         })}
      >
         {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

         <div
            style={{
               height: itemContext.dimensions.height,
               overflow: "hidden",
               paddingLeft: 3,
               textOverflow: "ellipsis",
               whiteSpace: "nowrap",
            }}
         >
            {item.title}
         </div>

         {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
   );
};

const customGroupRenderer = ({ group }: { group: TimelineGroupBase }) => {
   return (
      <div
         style={
            {
               // borderRadius: "8px",
               // border: "1px solid #ddd",
               // backgroundColor: "#f9f9f9",
               // color: "#333",
               // padding: "8px",
               // margin: "4px 0",
               // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }
         }
      >
         <div>1 Helen St, McKees Rocks, PA 15136</div>
         <div>Market</div>
         <div>Team Lead</div>
      </div>
   );
};
