import classNames from "classnames/bind";
import styles from "./TransactionCalendarTab.module.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./TransactionCalendarTab.scss";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useQTrans_Transactions } from "../../../hooks/transactions/queries/useQTrans_Transactions";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import {
   useSoSOptions_Transaction_ClosingStatus,
   useSoSOptions_Transaction_EMDStatus,
   useSoSOptions_Transaction_FinancingTeam,
   // useSoSOptions_Transaction_Inspection,
   useSoSOptions_Transaction_TitleCompany,
} from "../../../hooks/queries/options/useQSoS_Options";

const cx = classNames.bind(styles);

export const TransactionCalendarTab = () => {
   const { oid } = useTransactionsContext().transactionsState;
   const localizer = momentLocalizer(moment);

   const { transactionMap } = useQTrans_Transactions();

   const qEMDStatusOptions = useSoSOptions_Transaction_EMDStatus().data?.options;

   // const qInspectionStatusOptions = useSoSOptions_Transaction_Inspection().data?.options || [];

   const qTitleCompanyStatusOptions = useSoSOptions_Transaction_TitleCompany().data?.options || [];

   const qFinancingStatusOptions = useSoSOptions_Transaction_FinancingTeam().data?.options || [];

   const qClosingStatusOptions = useSoSOptions_Transaction_ClosingStatus().data?.options || [];

   const transaction = transactionMap[oid];

   if (!transaction) return null;

   const {
      emd_status,
      emd_start_date,
      emd_end_date,
      // inspection_status,
      // inspection_start_date,
      // inspection_end_date,
      title_status,
      title_start_date,
      title_end_date,
      financing_status,
      financing_start_date,
      financing_end_date,
      closing_status,
      closing_start_date,
      closing_end_date,
   } = transaction;

   const events = [
      {
         start: new Date(emd_start_date),
         end: new Date(emd_end_date),
         title: emd_status,
         status: emd_status,
         color: qEMDStatusOptions?.find((option) => option.label === emd_status)?.color || "#1a2d3d37",
      },
      // {
      //    start: new Date(inspection_start_date),
      //    end: new Date(inspection_end_date),
      //    title: inspection_status,
      //    status: inspection_status,
      //    color: qInspectionStatusOptions.find((option) => option.label === inspection_status)?.color || "#1a2d3d37",
      // },
      {
         start: new Date(title_start_date),
         end: new Date(title_end_date),
         title: title_status,
         status: title_status,
         color: qTitleCompanyStatusOptions.find((option) => option.label === title_status)?.color || "#1a2d3d37",
      },
      {
         start: new Date(financing_start_date),
         end: new Date(financing_end_date),
         title: financing_status,
         status: financing_status,
         color: qFinancingStatusOptions.find((option) => option.label === financing_status)?.color || "#1a2d3d37",
      },
      {
         start: new Date(closing_start_date),
         end: new Date(closing_end_date),
         title: closing_status,
         status: closing_status,
         color: qClosingStatusOptions.find((option) => option.label === closing_status)?.color || "#1a2d3d37",
      },
   ];

   return (
      <div className={cx("container")}>
         <Calendar
            eventPropGetter={eventStyleGetter}
            localizer={localizer}
            events={events}
            popup
            startAccessor="start"
            endAccessor="end"
         />
      </div>
   );
};

const eventStyleGetter = (event: { status: string; color: string }) => {
   const backgroundColor = event.color;

   return {
      style: {
         backgroundColor,
         height: "100%",
         color: "white",
         borderRadius: "5px",
         border: "none",
      },
   };
};
