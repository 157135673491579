import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useToastContext } from "../../../contexts/ToastContext";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useQTrans_Transaction } from "../queries/useQTrans_Transaction";

export const useMTrans_ScheduleWalkthrough = () => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const { oid, pidSelected } = useTransactionsContext().transactionsState;

   const qGetTransaction = useQTrans_Transaction();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Schedule Walkthrough is successful!",
            },
         });
         qGetTransaction.refetch();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Schedule Walkthrough is failed!",
            },
         });
      },

      mutationFn: (createBody: Transactions_ScheduleWalkThrough_Body) =>
         sosAxios
            .post(`/transactions/${oid}/properties/${pidSelected}/walkthroughs`, { ...createBody })
            .then<Transactions_ScheduleWalkThrough_Response>(({ data }) => data),
   });
};

type Transactions_ScheduleWalkThrough_Response = {
   message: string;
};

export type Transactions_ScheduleWalkThrough_Body = {
   walkthrough_date: string;
   reno_person: string;
   assigned_poc: string;
   notes?: string;
};
