import { TApp } from "../../../constants/apps";
import styles from "./AppCard.module.scss";
import classNames from "classnames/bind";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useMApps_ToggleAppSubscription } from "../../../hooks/apps/mutates/useMApps_ToggleAppSubscription";
import { useToastContext } from "../../../contexts/ToastContext";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/AppContext";
import { useQSOS_Profile } from "../../../hooks/queries/user/useQSOS_Profile";

const cx = classNames.bind(styles);

const AppCard = ({ appItem }: AppCardProps) => {
   const navigate = useNavigate();
   const { appState } = useAppContext();

   const { currentUser } = appState;

   const { subscribed_apps } = currentUser;

   const { toastDispatch } = useToastContext();

   const { mutate, isPending } = useMApps_ToggleAppSubscription({
      onError: () => {
         toastDispatch({ type: "ADD_TOAST", payload: { type: "success", message: "Subscription the app failed!" } });
      },
      onSuccess: () => {
         qProfile.refetch();
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "success", message: "Subscription the app succesfully!" },
         });
      },
   });

   const qProfile = useQSOS_Profile();

   const isSubscribed = subscribed_apps?.includes(appItem.id);

   const handleSubscribe = async () => {
      mutate({ appId: appItem.id, subscribe: !isSubscribed });
   };

   return (
      <div className={cx("contentC")}>
         <div className={cx("tittleC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL(`/assets/${appItem.icon}`)} alt="" />
            </div>
            <span>{appItem.name}</span>
            <SHrSosButton
               // type={isSubscribed ? "outlined" : "filled"}
               // loading={qProfile.isFetching || isPending}
               buttonProps={{
                  className: cx("btn-setting"),
               }}
            >
               <img src={CF_CDN_URL("/assets/settings_black.svg")} alt="" />
               Settings
            </SHrSosButton>
         </div>
         <div className={cx("description")}>
            {appItem.description} <div className={cx("describeBox")}>{appItem.description}</div>
         </div>

         <div className={cx("btnC")}>
            <SHrSosButton
               type={isSubscribed ? "outlined" : "filled"}
               loading={qProfile.isFetching || isPending}
               buttonProps={{
                  className: cx("btn-subcribe", { unsubcribe: isSubscribed }),
                  onClick: handleSubscribe,
               }}
            >
               <img src={CF_CDN_URL(`/assets/${isSubscribed ? "remove_black.svg" : "plus_white.svg"}`)} alt="" />
               {isSubscribed ? "Unsubscribe" : "Subscribe"}
            </SHrSosButton>
            {isSubscribed && (
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn-subcribe"),
                     onClick: () => navigate(appItem.path),
                  }}
               >
                  Open
               </SHrSosButton>
            )}

            {/* <NavLink className={cx("img-new")} to={appItem.path}>
               <img src={CF_CDN_URL("/assets/open_in_new_black.svg")} alt="" />
            </NavLink> */}
         </div>
      </div>
   );
};

export default AppCard;
type AppCardProps = {
   appItem: TApp;
};
