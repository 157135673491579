import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useOffersQueryParams } from "../../hooks/useOffersQueryParams";
import { SoS_GetOptions_Option, useSoSOptions_Offers_Leads_Statuses } from "../../../queries/options/useQSoS_Options";

export const useQOffers_GetLeads = () => {
   const { sosAxios } = useSosAxios();
   const { filters } = useOffersQueryParams();
   const queryClient = useQueryClient();

   const qOpLeadStatuses = useSoSOptions_Offers_Leads_Statuses();
   const leadStatusesMap =
      qOpLeadStatuses.data?.options.reduce(
         (acc, o) => ({ ...acc, [o.value]: o }),
         {} as Record<string, SoS_GetOptions_Option>
      ) || {};

   const query = useQuery({
      queryKey: [`useQOffers_GetLeads`, filters.assignee, filters.cids],
      staleTime: 500,
      enabled: !qOpLeadStatuses.isLoading,
      queryFn: () =>
         sosAxios
            .get(`/offers/leads`, { params: { ...filters } })
            .then(({ data }: { data: SoS_Offers_GetLeads_Response_Dto }) => {
               const leads = data.leads;
               const leadsMap = leads.reduce((acc, lead: SoS_Offers_GetAllLeads_Lead) => {
                  acc[lead.lid] = lead;
                  return acc;
               }, {} as Record<string, SoS_Offers_GetAllLeads_Lead>);
               const leadsGroupedByStatus = leads.reduce((acc, lead: SoS_Offers_GetAllLeads_Lead) => {
                  if (!leadStatusesMap[lead.status]) {
                     acc["Others"] = acc["Others"] || [];
                     acc["Others"].push(lead);
                     return acc;
                  }
                  acc[lead.status] = acc[lead.status] || [];
                  acc[lead.status].push(lead);
                  return acc;
               }, {} as Record<string, SoS_Offers_GetAllLeads_Lead[]>);
               return { leads, leadsMap, leadsGroupedByStatus };
            }),
   });

   const refetch = () => {
      queryClient.invalidateQueries({
         queryKey: [`useQOffers_GetLeads`, filters.assignee, filters.cids],
      });
      queryClient.refetchQueries({
         queryKey: [`useQOffers_GetLeads`, filters.assignee, filters.cids],
      });
   };

   return {
      ...query,
      refetch,
   };
};

export class SoS_Offers_GetAllLeads_Lead {
   lid!: number;
   status!: string;
   offer_status!: string;
   name!: string;
   created_at!: string;
   sid!: number;
   properties_count!: number;
   assignee!: string[];
   next_actions!: string[];
}

export class SoS_Offers_GetLeads_Response_Dto {
   leads!: SoS_Offers_GetAllLeads_Lead[];
}
