import classNames from "classnames/bind";
import styles from "./OffersLeadOverview.module.scss";
import { SoSDataPoint } from "../../../../components/SoSDataPoint/SoSDataPoint";
import { useSoSOptions_Offers_Lead_Next_Actions } from "../../../../hooks/queries/options/useQSoS_Options";
import { useQOffers_Lead } from "../../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../hooks/database/mutations/utils";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { useQOffers_GetLeads } from "../../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { formatDate } from "../../OffersLeadCard/OffersLeadCard";
import { useQ_Users } from "../../../../hooks/queries/user/useQ_Users";
import { useMOffers_Leads_UpdateLeadAssignee } from "../../../../hooks/offers/mutates/leads/useMOffers_Leads_UpdateLeadAssignee";

const cx = classNames.bind(styles);

export const OffersLeadOverview = () => {
   const { offersState } = useOffersContext();
   const { lid } = offersState;

   const qOffersLead = useQOffers_Lead();
   const qOffersLeads = useQOffers_GetLeads();
   const qOffersLeadNextActions = useSoSOptions_Offers_Lead_Next_Actions();
   const qUsers = useQ_Users();
   const { mutate } = useMOffers_Leads_UpdateLeadAssignee();

   const { options: leadNextActionOptions } = qOffersLeadNextActions.data || {};

   const userOptions = qUsers.data?.users.map((u) => ({ label: u.name, value: u.uid })) || [];

   if (!qOffersLead.data?.lead) return null;
   const lead = qOffersLead.data.lead;
   const selectedUsers = lead.assignee.map((uid) => userOptions.find((u) => u.value === uid)?.label);
   const db_L = dbcb("sos_leads", [{ column: "lid", id: lid }], () => {
      qOffersLead.refetch();
      qOffersLeads.refetch();
   });

   const handleUpdateLeadAssignee = (values: string[]) => {
      mutate({ uids: values });
   };

   return (
      <div className={cx("container")}>
         <SoSDataPoint title="Asking Price" type="bold-value" format="currency" value={lead.source.asking_price} />
         <SoSDataPoint title="Total Max Price" type="bold-value" format="currency" value={lead.total_max_price} />
         <SoSDataPoint title="Target Offer Amount" type="bold-value" format="currency" value={lead.total_max_price} />
         <SoSDataPoint
            title="Cap Rate (Cumulative)"
            type="bold-value"
            format="percentage"
            value={lead.cap_rate_cumulative}
            history={{ table: "sos_leads", column: "cap_rate_cumulative", record_id: lid.toString() }}
         />
         <SoSDataPoint
            title="ARV Uplift (Cumulative)"
            type="bold-value"
            format="percentage"
            value={lead.arv_uplift_cumulative}
         />
         <SoSDataPoint title="Total ARV" type="bold-value" format="currency" value={lead.total_arv} />
         <SoSDataPoint title="Total All In" type="bold-value" format="currency" value={lead.total_all_in} />
         <SoSDataPoint title="Created Date" type="bold-title" value={formatDate(lead.created_at)} isAdd />
         <SoSDataPoint
            title="Lead Assignee"
            type="bold-title"
            value={lead.assignee}
            onOptionSelect={(values) => handleUpdateLeadAssignee(values.map((v) => v.value))}
            displayValue={selectedUsers.join(", ")}
            isAdd
            options={userOptions}
            options_isMulti
         />
         <SoSDataPoint
            title="Lead Next Actions"
            type="bold-title"
            options_isMulti
            value={lead.next_actions}
            isAdd
            options={leadNextActionOptions}
            database={db_L("next_actions")}
         />
      </div>
   );
};
