import classNames from "classnames/bind";
import styles from "./TransactionPage.module.scss";
import { SoSInputTitle } from "../../components/common/soSInputTitle/SoSInputTitle";
import SoSFilter from "../../components/common/SoSFilter/SoSFilter";
import { SoSSwich } from "../../components/common/SoSSwich/SoSSwich";
import { TransactionListView } from "./TransactionListView/TransactionListView";
import { OptionType } from "../../components/SoSInputForm/SoSInputForm";
import { SoSInputSelect } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { useState } from "react";
import {
   useSoSOptions_Transaction_ClosingStatus,
   useSoSOptions_Transaction_EMDStatus,
   useSoSOptions_Transaction_FinancingTeam,
   useSoSOptions_Transaction_Inspection,
   useSoSOptions_Transaction_InsuranceStatus,
   useSoSOptions_Transaction_LeaseReview,
   useSoSOptions_Transaction_TitleCompany,
   useSoSOptions_Transaction_UnderwritingTeam,
} from "../../hooks/queries/options/useQSoS_Options";
import { TransactionTimeLineView } from "./TransactionTimeLineView/TransactionTimeLineView";
import { TransactionsProvider } from "../../contexts/TransactionsContext";
import { PViPropertyDetailsModal } from "../PropertyViabilityPage/PViPropertyDetailsModal/PViPropertyDetailsModal";
const cx = classNames.bind(styles);

export const TransactionPage = () => {
   return (
      <TransactionsProvider>
         <Transaction />
      </TransactionsProvider>
   );
};

export const Transaction = () => {
   const [view, setView] = useState(false);
   return (
      <div className={cx("contentC")}>
         <TransactionTop view={view} setView={setView} />
         {!view ? <TransactionListView /> : <TransactionTimeLineView />}
         <PViPropertyDetailsModal />
      </div>
   );
};

const options: OptionType[] = [
   { value: "emd", label: "EMD" },
   { value: "inspection", label: "Inspection" },
   { value: "closing", label: "EMD due date" },
   { value: "market", label: "Market" },
   { value: "assignee", label: "Assignee" },
];

type TransactionTopProps = {
   view: boolean;
   setView: (view: boolean) => void;
};
const TransactionTop = ({ view, setView }: TransactionTopProps) => {
   const qEMDStatus = useSoSOptions_Transaction_EMDStatus().data?.options || [];
   const EMDStatusOptions = qEMDStatus.map((e) => ({ label: e.label, value: e.value }));

   const qInspection = useSoSOptions_Transaction_Inspection().data?.options || [];
   const qInspectionStatusOptions = qInspection.map((i) => ({ label: i.label, value: i.value }));

   const qTitleCompany = useSoSOptions_Transaction_TitleCompany().data?.options || [];
   const qTitleCompanyStatusOptions = qTitleCompany.map((t) => ({ label: t.label, value: t.value }));

   const qInsuranceStatus = useSoSOptions_Transaction_InsuranceStatus().data?.options || [];
   const InsuranceStatusOptions = qInsuranceStatus.map((i) => ({ label: i.label, value: i.value }));

   const qLeaseReview = useSoSOptions_Transaction_LeaseReview().data?.options || [];
   const LeaseReviewOptions = qLeaseReview.map((l) => ({ label: l.label, value: l.value }));

   const qUnderwritingTeam = useSoSOptions_Transaction_UnderwritingTeam().data?.options || [];
   const UnderwritingTeamOptions = qUnderwritingTeam.map((u) => ({ label: u.label, value: u.value }));

   const qFinancingTeam = useSoSOptions_Transaction_FinancingTeam().data?.options || [];
   const qFinancingStatusOptions = qFinancingTeam.map((u) => ({ label: u.label, value: u.value }));

   const qClosingStatus = useSoSOptions_Transaction_ClosingStatus().data?.options || [];
   const qClosingStatusOptions = qClosingStatus.map((u) => ({ label: u.label, value: u.value }));

   return (
      <div className={cx("top")}>
         <div className={cx("topLeft")}>
            <div className={cx("input_search")}>
               <SoSInputTitle
                  value={""}
                  type="text"
                  handleValue={() => {}}
                  icon="search"
                  placeholder="Start searching"
               />
            </div>

            <div className={cx("filterList")}>
               <div className={cx("filterAndTitleC")}>
                  <span>Visible</span>
                  <SoSFilter
                     title="Select Fields"
                     type="select"
                     isMulti={true}
                     value={[options[0]]}
                     onChange={() => {}}
                     options={options}
                     className={cx("filter-dropdown")}
                  />
               </div>

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("filterAndTitleC")}>
                  <span>Filter</span>
                  <div className={cx("selectOptionC")}>
                     <SoSInputSelect
                        isMulti={false}
                        value={options[0].label}
                        onChange={(o) => {}}
                        options={options}
                        SelectProps={{ isSearchable: false }}
                     />
                  </div>
               </div>

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("filterAndTitleC")}>
                  <span>Sort By</span>
                  <div className={cx("selectOptionC")}>
                     <SoSInputSelect
                        isMulti={false}
                        value={EMDStatusOptions[0]?.label}
                        onChange={(o) => {}}
                        options={EMDStatusOptions}
                        SelectProps={{ isSearchable: false }}
                     />
                  </div>
                  <div className={cx("selectOptionC")}>
                     <SoSInputSelect
                        isMulti={false}
                        value={qInspectionStatusOptions[0]?.label}
                        onChange={(o) => {}}
                        options={qInspectionStatusOptions}
                        SelectProps={{ isSearchable: false }}
                     />
                  </div>
               </div>
            </div>
         </div>

         <div className={cx("topRight")}>
            <div className={cx("filterAndTitleC")}>
               <span>Show as Timeline</span>
               <SoSSwich checked={view} onChange={() => setView(!view)} />
            </div>

            <div className={cx("selectOptionC")}>
               <SoSInputSelect
                  isMulti={false}
                  value={options[0].label}
                  onChange={(o) => {}}
                  options={options}
                  SelectProps={{ isSearchable: false }}
               />
            </div>
         </div>
      </div>
   );
};
