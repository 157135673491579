import { useInfiniteQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useTransQueryParams } from "../hooks/useTransQueryParams";
import { useMemo } from "react";

export const useQTrans_Transactions = () => {
   const { sosAxios } = useSosAxios();

   const { filters } = useTransQueryParams();

   const baseParams = { ...filters };

   const query = useInfiniteQuery({
      queryKey: [`useQTrans_Transactions`, baseParams],
      staleTime: 500,
      queryFn: ({ pageParam: offset }) =>
         sosAxios
            .get(`/transactions`, { params: { ...baseParams, offset } })
            .then<GetTransactions_Transactions_Pagination>(
               ({ data }: { data: GetTransactions_Transactions_Pagination }) => {
                  const transactions = data.payload.transactions;

                  const transactionsMap = transactions.reduce(
                     (
                        acc: Record<string, Transactions_GetTransactions_Transaction>,
                        transaction: Transactions_GetTransactions_Transaction
                     ) => {
                        acc[transaction.oid] = transaction;
                        return acc;
                     },
                     {} as Record<string, Transactions_GetTransactions_Transaction>
                  );
                  return {
                     payload: {
                        transactions,
                        transactionsMap,
                     },
                     metadata: data.metadata,
                  };
               }
            ),
      initialPageParam: 0,
      getNextPageParam: ({ metadata: { nextOffset } }) =>
         nextOffset > 0 && nextOffset >= +baseParams.limit ? nextOffset : undefined,
   });

   const transactions = useMemo(
      () => query.data?.pages.flatMap((page) => page.payload.transactions) || [],
      [query.data?.pages]
   );

   const transactionMap = useMemo(
      () =>
         query.data?.pages.reduce(
            (acc, page) => ({ ...acc, ...page.payload.transactionsMap }),
            {} as Record<string, Transactions_GetTransactions_Transaction>
         ) || {},
      [query.data?.pages]
   );

   return { ...query, transactions, transactionMap };
};
export class Transactions_GetTransactions_Transaction_Inspection {
   iid!: number;
   oid!: number;
   pid!: string;
   status!: string;
   start_date!: string;
   end_date!: string;
}
export class Transactions_GetTransactions_Transaction {
   oid!: number;
   lid!: number;
   name!: string;
   emd_status!: string;
   title_status!: string;
   financing_status!: string;
   closing_status!: string;
   emd_start_date!: string;
   emd_end_date!: string;
   title_start_date!: string;
   title_end_date!: string;
   financing_start_date!: string;
   financing_end_date!: string;
   closing_start_date!: string;
   closing_end_date!: string;
   market_name!: string;
   inspection!: Transactions_GetTransactions_Transaction_Inspection[];
}

export type Transactions_GetTransactions_Response = {
   transactions: Transactions_GetTransactions_Transaction[];
};

type GetTransactions_Response = {
   transactions: Transactions_GetTransactions_Transaction[];
   transactionsMap: Record<string, Transactions_GetTransactions_Transaction>;
};

type GetTransactions_Transactions_Pagination = {
   payload: GetTransactions_Response;
   metadata: { totalRows: number; nextOffset: number };
};
