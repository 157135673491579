import classNames from "classnames/bind";
import styles from "./TransactionPropertySwitch.module.scss";
import { TransactionPropertyCard } from "./TransactionPropertyCard/TransactionPropertyCard";
import { Transactions_GetTransactionById_Transaction_Property } from "../../../hooks/transactions/queries/useQTrans_Transaction";
import { SHrSpinner } from "@simplyhomes/react";
import { usePViContext } from "../../../contexts/PViContext";
import { useEffect } from "react";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";

const cx = classNames.bind(styles);

type TTransPropertySwitchProps = {
   properties: Transactions_GetTransactionById_Transaction_Property[];
};

export const TransactionPropertySwitch = ({ properties }: TTransPropertySwitchProps) => {
   const { pviState, pviDispatch } = usePViContext();

   const { transactionsDispatch } = useTransactionsContext();

   const { pid } = pviState;

   useEffect(() => {
      if (properties?.some((property) => property?.pid === pid)) return;
      transactionsDispatch({ overwrite: { pidSelected: properties?.[0]?.pid } });
      pviDispatch({ overwrite: { pid: properties?.[0]?.pid } });
   }, [properties, pid, transactionsDispatch, pviDispatch]);

   return (
      <div className={cx("container")}>
         {!properties ? (
            <div className={cx("spinnerC")}>
               <SHrSpinner />
               <span>Loading Properties...</span>
            </div>
         ) : (
            properties.map((property) => <TransactionPropertyCard key={property.pid} pid={property.pid} />)
         )}
      </div>
   );
};
