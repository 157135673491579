import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useQOffers_Lead } from "../../queries/leads/useQOffers_GetLead";
import { useQOffers_GetLeads } from "../../queries/leads/useQOffers_GetLeads";

export const useMOffers_Leads_UpdateLeadAssignee = () => {
   const { toastDispatch } = useToastContext();
   const { sosAxios } = useSosAxios();

   const qOffersLead = useQOffers_Lead();
   const qOffersLeads = useQOffers_GetLeads();

   const { offersState } = useOffersContext();

   const { lid } = offersState;

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               message: "Updated lead assignee is successful!",
               type: "success",
            },
         });
         qOffersLead.refetch();
         qOffersLeads.refetch();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               message: "Updated lead assignee is failed!",
               type: "error",
            },
         });
      },
      mutationFn: ({ uids }: TUpdateLeadAssignneParams) =>
         sosAxios
            .put(`/offers/leads/${lid}/assignee`, { uids })
            .then<TSoS_Offers_UpdateLeadUsers_Response>(({ data }) => data),
   });
};

type TUpdateLeadAssignneParams = {
   uids: string[];
};

export type TSoS_Offers_UpdateLeadUsers_Response = {
   message: string;
};
